import React from 'react';
import frontal from "../../resources/bowls/frontal.png"
import superior from "../../resources/bowls/superior.png"
import { allergenData, AllergenIcon } from '../allergenIcon/AllergenIcon';
import ProductCarousel from '../productCarousel/ProductCarousel';
import "./index.css"

export class ProductCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
      }
    
      componentDidMount() {
      }
  
      render() {
          return (
            <div className={"productCard row mb-3 justify-content-center ps-1 " + this.props.class}>
                <ProductCarousel className="mx-auto col-12 col-lg-5" frontal={frontal} superior={superior}></ProductCarousel>
                <div className="col-12 col-lg-7 text">
                    <div className="row">
                        <h1>Bowl de açai</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut ac elit velit. Curabitur semper scelerisque nunc a bibendum. Nunc tincidunt eu risus non interdum. </p>
                    </div>
                    <div className="row mt-3">
                        <AllergenIcon allergens={[allergenData("nuts", "yes"), allergenData("milk", "yes")]}/>
                    </div>
                </div>
            </div>
          );
      }
}

ProductCard.defaultProps = {
    class : ""
}