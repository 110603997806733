import React from 'react';
import emailjs from 'emailjs-com';
import { Footer } from '../../components/footer/Footer';
import NavBar from '../../components/navbar';
import "./index.css"


export class ContactScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.form = React.createRef();
        this.sendEmail = this.sendEmail.bind(this)

    }

    componentDidMount() {

    }

    sendEmail(e) {
        e.preventDefault();
        this.setState({
            successMsg: null,
            errorMsg: null
        })
        emailjs.sendForm('contactUs', 'template_kr6encd', this.form.current, 'ELCUdRa0-VlkAaYVe')
            .then((result) => {
                e.target.reset();
                this.setState({
                    successMsg: result.text
                })
            }, (error) => {
                this.setState({
                    errorMsg: error.text
                })
            });
    };

    render() {
        return (
            <div id='contactUs'>
                <div id="bg" className="position-fixed" style={{
                    minHeight: '100vh',
                    minWidth: '100vw',
                    height: document.documentElement.scrollHeight + "px",
                    zIndex: -1
                    //backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/pattern.png'})`
                }}>
                </div>

                <NavBar withHero={false} />

                <div className="container mt-5 mb-5 mb-md-0">
                    <div className="card" data-aos="zoom-in-up" style={{
                        backgroundColor: 'transparent'
                    }}>
                        <div className="image w-100 h-100" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/products/pirulin.JPG'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            minHeight: '200px',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            zIndex: 0,
                            opacity: 0.2
                        }}></div>
                        <div className="image w-100 h-100" style={{
                            backgroundColor: 'white',
                            minHeight: '200px',
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            zIndex: -1,
                            opacity: 1
                        }}></div>
                        <div className="card-body" style={{
                            zIndex: '1'
                        }}>
                            <h2 className="h1-responsive font-weight-bold text-center my-4">¡Contacta con nosotros!</h2>
                            <p className="text-center w-responsive mx-auto mb-1">Cualquier pregunta o sugerencia es importante para nosotros</p>
                            <div className={ (this.state.successMsg? "success" : this.state.errorMsg? "error": "" ) + " row mb-1"} id='responseMessage'> 
                                { this.state.successMsg && <div>Enviado con éxito: {this.state.successMsg}</div>}
                                { this.state.errorMsg && <div>Ha ocurrido un error: {this.state.errorMsg} <br/> Por favor pongase en contacto con nosotros por otro medio</div>}
                            </div>
                            <div className="row">

                                <div className="col-md-9 mb-md-0 mb-5">
                                    <form id="contact-form" name="contact-form" ref={this.form} onSubmit={this.sendEmail}>

                                        <div className="row">

                                            <div className="col-md-6">
                                                <div className="md-form mb-0">
                                                    <label htmlFor="name" className="">Tu nombre:</label>
                                                    <input type="text" id="name" name="name" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="md-form mb-0">
                                                    <label htmlFor="email" className="">Tu email:</label>
                                                    <input type="text" id="email" name="email" className="form-control" />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="md-form mb-0">
                                                    <label htmlFor="subject" className="">Asunto</label>
                                                    <input type="text" id="subject" name="subject" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <div className="md-form">
                                                    <label htmlFor="message">Mensaje:</label>
                                                    <textarea type="text" id="message" name="message" rows="2"
                                                        className="form-control md-textarea"></textarea>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="d-grid gap-2 mb-3 mt-5">
                                            <button type="submit" className="btn btn-primary py-3">Enviar</button>
                                        </div>
                                    </form>

                                    <div className="status"></div>
                                </div>
                                <div className="col-md-3 text-center">
                                    <ul className="list-unstyled mb-0">
                                        <li><i className="fas fa-map-marker-alt fa-2x"></i>
                                            <p>Avda. Infante Don Luis 3</p>
                                        </li>

                                        <li><i className="fas fa-phone mt-4 fa-2x"></i>
                                            <p>+ 34 917 270 265</p>
                                        </li>

                                        <li><i className="fas fa-envelope mt-4 fa-2x"></i>
                                            <p>hola@baluacai.com</p>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                

                <Footer />
            </div>
        );
    }
}

export default ContactScreen;