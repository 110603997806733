import React from 'react';
//import { Parallax } from 'react-parallax';
import NavBar from "../../components/navbar/index.js"
import "./index.css"
import { Footer } from '../../components/footer/Footer.js';
import { useNavigate, useNavigation } from "react-router-dom";
import {
  MDBCarousel,
  MDBCarouselItem,
  MDBBtn
} from 'mdb-react-ui-kit';



export class MainScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};


    this.redirectLocation = this.redirectLocation.bind(this);

    this.redirectMenu = this.redirectMenu.bind(this)
  }

  componentDidMount() {
  }

  redirectLocation=()=> {
    let path = `locations`;
    useNavigation().navigate(path);
  }

  redirectMenu=()=> {
    let path = `menu`;
    useNavigation().navigate(path);
  }


  render() {

    return (
      <div id='homeScreen'>

        <div id="bg" className="position-fixed" style={{
          minHeight: '100vh',
          minWidth: '100vw',
          height: document.documentElement.scrollHeight + "px",
          zIndex: -1
          //backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/pattern.png'})`
        }}>

        </div>

        <NavBar heroSection={
          <div id='homeHero' className='mb-5'>
            <div className="container h-100 ">
              <div className='row  d-flex align-items-center justify-content-center '>
                  <div className="col text-white h-100 d-flex align-items-center justify-content-center flex-column" style={{ textAlign: 'center' }} data-aos="zoom-in-up">
                    <h1> ¡Por fin abrimos nuestras puertas!</h1>
                    <h2 className='my-2'> Ven a visitarnos</h2>
                    <div className='my-4'> 

                    <a href="menu" class="btn btn-light btn-rounded mx-2">Carta</a>
                    <a href="locations" class="btn btn-light btn-rounded mx-2">Ubicación</a>
                    
                    </div>

                </div>
                <div className='col-12 col-md-8'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col px-5 my-5 mx-auto'>
                        <MDBCarousel showControls>
                          <MDBCarouselItem
                            className='d-block mx-auto'
                            itemId={1}
                            src={`${process.env.PUBLIC_URL + '/resources/products/bowl1.JPG'}`}
                            alt='...'
                          />
                          <MDBCarouselItem
                            className='d-block'
                            itemId={2}
                            src={`${process.env.PUBLIC_URL + '/resources/products/takeAway.JPG'}`}
                            alt='...'
                          />
                          <MDBCarouselItem
                            className='d-block'
                            itemId={3}
                            src={`${process.env.PUBLIC_URL + '/resources/products/bowl2.JPG'}`}
                            alt='...'
                          />
                          <MDBCarouselItem
                            className='d-block'
                            itemId={3}
                            src={`${process.env.PUBLIC_URL + '/resources/products/tarta.JPG'}`}
                            alt='...'
                          />
                          <MDBCarouselItem
                            className='d-block'
                            itemId={3}
                            src={`${process.env.PUBLIC_URL + '/resources/products/queso.JPG'}`}
                            alt='...'
                          />
                        </MDBCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        } />



        <Footer />
      </div>
    );
  }
}

export default MainScreen;
