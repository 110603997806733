import React from 'react';
//import { Parallax } from 'react-parallax';
import NavBar from "../../components/navbar/index.js"
import { ProductCard } from '../../components/productCard/ProductCard.js';
import "./index.css"
import { Footer } from '../../components/footer/Footer.js';


export class MenuScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [
        {
          squares: Array(9).fill(null)
        }
      ],
      stepNumber: 0,
      xIsNext: true
    };
  }

  componentDidMount() {
  }

  render() {

    return (
      <div>
        <div id="bg" className="position-fixed" style={{
          minHeight: '100vh',
          minWidth: '100vw',
          height: document.documentElement.scrollHeight + "px",
          zIndex: -1
          //backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/pattern.png'})`
        }}>

        </div>

        <NavBar isMenu={false} withHero={false}/>
        
        
        <div className='row d-block d-md-none'>
          <div className='col-12'>
            <img className='w-100' src={`${process.env.PUBLIC_URL + '/resources/Carta Movil/1.png'}`}></img>
          </div>
        </div>
        <div className='row d-block d-md-none'>
          <div className='col-12'>
            <img className='w-100'  src={`${process.env.PUBLIC_URL + '/resources/Carta Movil/2.png'}`}></img>
          </div>
        </div>
        <div className='row d-block d-md-none'>
          <div className='col-12'>
            <img className='w-100'  src={`${process.env.PUBLIC_URL + '/resources/Carta Movil/3.png'}`}></img>
          </div>
        </div>
        <div className='row d-block d-md-none'>
          <div className='col-12'>
            <img className='w-100'  src={`${process.env.PUBLIC_URL + '/resources/Carta Movil/4.png'}`}></img>
          </div>
        </div>
        <div className='row d-none d-md-flex justify-content-center mt-4'>
          <div className='col-8'>
            <img className='w-100'  src={`${process.env.PUBLIC_URL + '/resources/A4 Menu/1.png'}`}></img>
          </div>
        </div>
        <div className='row d-none d-md-flex justify-content-center mb-4'>
          <div className='col-8'>
            <img className='w-100'  src={`${process.env.PUBLIC_URL + '/resources/A4 Menu/2.png'}`}></img>
          </div>
        </div>
        
        <script src="./script.js"></script>
        <Footer/>
      </div>
    );
  }
}

export default MenuScreen;
