import React from 'react';
import ReactDOM from 'react-dom/client';
import MainScreen from './screens/Home/MainScreen.js'
import { BrowserRouter, Route, Routes, Navigate, useNavigation} from 'react-router-dom';
import './index.css';
import MenuScreen from './screens/Menu/Menu.js';
import ContactScreen from './screens/Contacto/ContactScreen.js';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import LocalesScreen from './screens/Locales/LocalesScreen.js';
import AboutUsScreen from './screens/AboutUs/AboutUsScreen.js';
import "aos/dist/aos.css";
import AOS from 'aos';
import $ from 'jquery';

const root = ReactDOM.createRoot(document.getElementById("root"));

window.addEventListener("load", function(event) {
    this.setTimeout(function() {
        $('#loading').css("display", "none"); }, 1000);
});

root.render(

    <BrowserRouter>
    <Routes>
        <Route exact path="/" element={<MainScreen />}></Route>
        <Route exact path="/menu" element={<MenuScreen />}></Route>
        <Route exact path="/contactUs" element={<ContactScreen />}></Route>
        <Route exact path="/locations" element={<LocalesScreen />}></Route>
        {/*<Route exact path="/aboutUs" element={<AboutUsScreen />}></Route>*/}
        <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
</BrowserRouter>
);

window.addEventListener("load", (event) => {
    AOS.init({
        mirror: true,
        delay: 500,
        duration: 2000
    });
    window.scrollTo(0,0);
  });

  window.onbeforeunload = function(event) {
    $("#loading").css("display", "block!important");
    window.scrollTo(100,0);
  };

