import React from 'react';
import "./index.css"
import { ReactComponent as Logo } from '../../resources/logo1.svg'

export class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }
    render() {
        return (
            <footer className="">
                <div className="row p-4 ">

                    <div className="d-block col-12  d-grid gap-2 justify-content-center">


                        <div className="d-flex justify-content-center mt-1 ">
                            <Logo className="logo" />
                        </div>
                        <div className="d-flex justify-content-center mb-3 mt-2">
                            {//<a className="btn btn-primary mx-3" style={{ backgroundColor: '#3b5998' }} href="https://www.instagram.com/balu_acai/" role="button"><i className="fab fa-facebook-f"></i></a>
    }
                           <a className="btn btn-primary mx-3" style={{ backgroundColor: '#ac2bac' }} href="https://www.instagram.com/balu_acai/" role="button"><i className="fab fa-instagram"></i></a>
    
                        </div>
                    </div>
                </div>
                <div className="row pb-1">
                    <div className="d-flex justify-content-center">
                        <p className="text-white"> &copy; 2022 Balú Bowls S.L. </p>
                    </div>
                </div>
            </footer>
        );
        /** 
        render() {
            return (
                <footer className="">
                    <div className="row p-4 ">
                        <div className="col-12 col-md-9">
                            <ul className="list-unstyled px-3 px-sm-5 d-flex flex-wrap align-content-around">
                                <li className="px-2"><a href="/" className="text-center nav-link px-3 text-white">Home</a></li>
                                <li className="px-2"><a href="/" className="text-center nav-link px-3 text-white">Home</a></li>
                                <li className="px-2"><a href="/" className="text-center nav-link px-3 text-white">Home</a></li>
                                <li className="px-2"><a href="/" className="text-center nav-link px-3 text-white">Home</a></li>
                                <li className="px-2"><a href="/" className="text-center nav-link px-3 text-white">Home</a></li>
                                <li className="px-2"><a href="/" className="text-center nav-link px-3 text-white">Home</a></li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-3 border-start d-none gap-2 justify-content-center d-md-grid">
                            <div className="d-flex justify-content-center my-0 mt-md-5 mb-3">
                                <Logo className="logo" />
                            </div>
                            <div className="d-flex justify-content-center my-0 mb-md-5">
                                <a className="btn btn-primary mx-3" style={{ backgroundColor: '#3b5998' }} href="#!" role="button"><i className="fab fa-facebook-f"></i></a>
    
                                <a className="btn btn-primary mx-3" style={{ backgroundColor: '#ac2bac' }} href="#!" role="button"><i className="fab fa-instagram"></i></a>
                            </div>
    
    
                        </div>
                        <div className="d-block d-md-none col-12 col-md-3 d-grid gap-2 justify-content-center">
    
                        <div className="d-flex justify-content-center my-0 mb-md-5">
                                <a className="btn btn-primary mx-3" style={{ backgroundColor: '#3b5998' }} href="#!" role="button"><i className="fab fa-facebook-f"></i></a>
    
                                <a className="btn btn-primary mx-3" style={{ backgroundColor: '#ac2bac' }} href="#!" role="button"><i className="fab fa-instagram"></i></a>
                            </div>
                            <div className="d-flex justify-content-center my-0 mt-md-5 mb-3">
                                <Logo className="logo" />
                            </div>
                        </div>
                    </div>
                    <div className="row pb-1">
                        <div className="d-flex justify-content-center">
                            <p className="text-white"> &copy; 2022 Balú Bowls S.L. </p>
                        </div>
                    </div>
                </footer>
            );
            */
    }
}