import React from 'react';
import "./index.css"

export class ProductCarousel extends React.Component {
	constructor(props) {
	  super(props);
	  this.state = {};
	}
  
	componentDidMount() {
	}

	render() {
		return (
            <div className={'productCarousel position-relative centerViewAutoCarousel ' + this.props.class}>
                <img src={this.props.frontal} className="frontal" alt="Logo" />
                <img src={this.props.superior} className="superior" alt="Logo" />
            </div>
        );
    }

}

export default ProductCarousel;