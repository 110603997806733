import React from 'react';
import "./resources/css/style.css"
import "./index.css"

export class AllergenIcon extends React.Component {
	constructor(props) {
	  super(props);
	  this.state = {};
	}
  
	componentDidMount() {
	}

	render() {
		return (
            <ul className={"AllergenIcon d-flex flex-row-reverse align-items-center mx-0 mb-2 eicon-list ng-scope " + this.props.class}>
                {this.props.allergens.map((item, index) => (
                    <li key={item.name} className={"my-0 ml-2 allergen-level-"+item.contain}><span className={"eicon eicon-circle-"+item.name}></span></li>
                ))}
              </ul>
        );
        }
    }

AllergenIcon.defaultProps = {
    allergens : [],
    class: ""
}

export function allergenData(name, contain) {
    return {
        'name' : name,
        'contain' : contain
    }
}