import React from 'react';
import { Footer } from '../../components/footer/Footer';
import NavBar from '../../components/navbar';
import "./index.css"


export class LocalesScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <div id='locales'>
                <div id="bg" className="position-fixed" style={{
                    minHeight: '100vh',
                    minWidth: '100vw',
                    height: document.documentElement.scrollHeight + "px",
                    zIndex: -1
                    //backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/pattern.png'})`
                }}>
                </div>

                <NavBar withHero={false} />

                <div className="container mt-5 d-flex flex-wrap">
                    <div className="card  w-100"  data-aos="zoom-in-up" style={{marginBottom: '70px', marginTop: '20px'}}>
                        <div className="row g-0">
                            <div className="col-8 col-md-5" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/locales/boadilla.webp'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            minHeight: '40vh',
                            height: 'calc(100% + 100px)',
                            position: 'relative',
                            top: '-50px',

                        }}> 
                            </div>
                            <div className="col-8 h-100" style={{marginLeft: '-3%', zIndex: '1', backgroundColor: 'white', position: 'absolute', right: '0'}}>
                                <div className="card-body">
                                    <h2 className="card-title fw-bolder">Boadilla del Monte</h2>
                                    <p className="card-text">
                                        Primer local de la marca y primer local de açai de la localidad y alrededores. 
                                    </p>
                                    <p className="card-text"><small className="">Avenida Infante don Luis, 3, Boadilla del Monte</small></p>
                                    <p className="card-text"><small className="text-muted">+34 917 270 265</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card  w-100"  data-aos="zoom-in-up" style={{marginBottom: '70px', marginTop: '70px'}}>
                        <div className="row g-0">
                            <div className="col-8 col-md-5" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/locales/boadilla.webp'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            minHeight: '40vh',
                            height: 'calc(100% + 100px)',
                            position: 'relative',
                            top: '-50px',

                        }}> 
                            </div>
                            <div className="col-8 h-100" style={{marginLeft: '-3%', zIndex: '1', backgroundColor: 'white', position: 'absolute', right: '0'}}>
                                <div className="card-body">
                                    <h2 className="card-title fw-bolder">Majadahonda</h2>
                                    <p className="card-text">
                                        
                                    </p>
                                    <p className="card-text"><small className="">Carretera de Boadilla, 2, Majadahonda</small></p>
                                    <p className="card-text"><small className="text-muted">+34 911 341 323</small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card  w-100"  data-aos="zoom-in-up" style={{marginBottom: '70px', marginTop: '70px'}}>
                        <div className="row g-0">
                            <div className="col-8 col-md-5" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/locales/boadilla.webp'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            minHeight: '40vh',
                            height: 'calc(100% + 100px)',
                            position: 'relative',
                            top: '-50px',

                        }}> 
                            </div>
                            <div className="col-8 h-100" style={{marginLeft: '-3%', zIndex: '1', backgroundColor: 'white', position: 'absolute', right: '0'}}>
                                <div className="card-body">
                                    <h2 className="card-title fw-bolder">Próximamente...</h2>
                                    <p className="card-text">
                                        ?????
                                    </p>
                                    <p className="card-text"><small className="text-muted">+&#9747;&#9747; &#9747;&#9747;&#9747; &#9747;&#9747;&#9747; &#9747;&#9747;&#9747; </small></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}

export default LocalesScreen;