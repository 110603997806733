import React from 'react';
import { Footer } from '../../components/footer/Footer';
import NavBar from '../../components/navbar';
import "./index.css"


export class AboutUsScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {

    }

    render() {
        return (
            <div id='aboutUs'>
                <div id="bg" className="position-fixed" style={{
                    minHeight: '100vh',
                    minWidth: '100vw',
                    height: document.documentElement.scrollHeight + "px",
                    zIndex: -1
                    //backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/pattern.png'})`
                }}>
                </div>

                <NavBar heroSection={
                    <div className="container h-100 " data-aos="zoom-in-up">
                        <div className="image w-100 d-none d-sm-block" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/products/takeAway.png'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            minHeight: '200px',
                            height: 'calc(100vh - 160px)',
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            zIndex: 0,
                            opacity: 0.2,
                            backgroundPositionX: 'right',
                            backgroundPositionY: 'bottom'
                        }}></div>
                        <div className="image w-100 d-block d-sm-none" style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL + '/resources/products/takeAway.png'})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            minHeight: '200px',
                            height: '60vh',
                            position: 'absolute',
                            bottom: '0',
                            right: '0',
                            zIndex: 0,
                            opacity: 0.2,
                            backgroundPositionX: 'center',
                            backgroundPositionY: 'bottom'
                        }}></div>
                        <div className="row align-items-md-center h-100" style={{ zIndex: '1' }}>
                            <div className="col-12 col-md-7">
                                <div className="text-white d-grid gap-2 container">
                                    <h1 className="col-12 fw-bolder text-center fs-1"> ¿Qué es Balú Açai?</h1>
                                    <p >Balú açai es una cabujasxha xajhbdsiha ixs adbhcbajhs xaj siba iwjxnajd cna sixnaijs kxn aijnwxia wkxhabcbkja wxkn aihwxbaijx kna cahbwxkwaj xkna wjx bwi xkna jsx ihabwjix ja wxabxi wa xi</p>
                                </div>
                            </div>
                            <div className="col">

                            </div>

                        </div>
                    </div>
                }
                />
                <div className="container my-5 px-md-0 px-5">
                    <div className="row">
                        <div className="col-12 col-md-5 header-container">
                            <h1 className='w-100 header-card'  data-aos-duration="1000" data-aos="fade-down">¿Qué es el açai?</h1>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className='col-12  col-md-10  body-card ' data-aos-duration="1000" data-aos="fade-right">
                            <p></p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-5  ms-auto header-container">
                            <h1 className='w-100 header-card' data-aos-duration="1000" data-aos="fade-down">Misión</h1>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className='col-12  col-md-10  body-card ms-auto ' data-aos-duration="1000" data-aos="fade-left">
                            <p></p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-5 header-container">
                            <h1 className='w-100 header-card' data-aos-duration="1000" data-aos="fade-down">Visión</h1>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className='col-12  col-md-10  body-card  ' data-aos-duration="1000" data-aos="fade-right">
                            <p></p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-5 ms-auto header-container">
                            <h1 className='w-100 header-card' data-aos-duration="1000" data-aos="fade-down">Nuestro equipo</h1>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className='col-12 col-md-10 body-card ms-auto ' data-aos-duration="1000" data-aos="fade-left">
                            <p></p>
                        </div>
                    </div>


                </div>
                <Footer />
            </div>
        );
    }
}

export default AboutUsScreen;